<template>
<div id="lawsList">
  <div class="lawsWrap">
    <div class="laws-header">
      <ul>
        <li @click="toggle(1)" :class="{active:type==1}">法律法规</li>
        <li @click="toggle(2)" :class="{active:type==2}">招聘须知</li>
        <li @click="toggle(3)" :class="{active:type==3}">求职技巧</li>
        <li @click="toggle(4)" :class="{active:type==4}">劳务纠纷案例</li>
      </ul>
    </div>
    <div class="laws-list" v-loading="loading">
      <div class="laws-item" v-for="(item,index) in examinatList" :key="index" @click="jumpLaws(item)">
        <h3><span>· </span>{{item.title}}</h3>
        <p>发布人：{{item.publisherName}}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getExaminationList } from "@/api/index.js";
export default {
  name: "lawsList",
  data() {
    return {
      type: 1,
      examinatList: [],
      loading:true
    }
  },
  mounted() {
    this.type = this.$route.query.type || 1
    this.getExaminationList()
  },
  methods: {
    toggle(type) {
      this.type = type
      this.getExaminationList()
    },
    jumpLaws(item) {
      this.$router.push({
        path: '/lawsDetail',
        query: {
          id: item.id
        }
      })
    },
    getExaminationList() {
      this.loading = true
      getExaminationList({
        type: this.type,
        page: 1,
        size: 20
      }).then(res => {
        this.loading = false
        this.examinatList = res.rows
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#lawsList {
  width: 100%;
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.lawsWrap {
  width: 1000px;
  min-height: 500px;
  margin: 0 auto 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #FFFFFF;
}

.laws-header {
  border-bottom: 1px solid #eee;
  height: 74px;

  li {
    float: left;
    min-width: 100px;
    height: 74px;
    line-height: 74px;
    text-align: center;
    margin-right: 100px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .active {
    border-bottom: 3px solid #0077FF;
    color: #2C87FF;
  }
}

.laws-item {
  clear: both;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  cursor: pointer;

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #222222;

    span {
      color: #0077FF;
      position: relative;
      top: -2px;
    }
  }

  p {
    margin-top: 15px;
    font-size: 15px;
    color: #999999;
    margin-left: 11px;
  }
}

.laws-item:hover {
  h3 {
    color: #0077FF;
  }
}
</style>
